import React, { Component } from "react";

import { Strings } from "../../../resources/i18n/i18n";

import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import PropTypes from "prop-types";
import { Col } from "react-bootstrap";
import { NumericFormat } from "react-number-format";

import {
  SEARCH_FILTER_TYPE,
  VISITED_DAYS,
  NOTES_TYPE,
  APPROVED_MINIMART_ACTION,
} from "../../../constants/appConstants";
import { ANALYST_ACTION } from "../../AnalystApproval/Constants";

import SearchFilter from "../SearchFilter/SearchFilter";
import Utility from "../../../utils/Utility";

class NotesModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: props.open,
      errorMessage: "",
      note: props.note ? props.note : "",
      vd: "",
      ownerCedulaId: this.props.oc,
    };
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.errorMessage !== this.props.errorMessage) {
      this.setState({ errorMessage: this.props.errorMessage });
    }
    if (prevProps.note !== this.props.note) {
      this.setState({ note: this.props.note });
    }
  };

  //to close modal
  closeModal = () => {
    this.props.toggleModal();
  };

  handleChangeValue = (event) => {
    let value = event.target.value;
    this.props.maxLength === event.target.textLength
      ? this.setState({
        note: value,
        errorMessage: `${event.target.textLength} ${Strings(
          "charactersAllowed"
        )}`,
      })
      : this.setState({ note: value, errorMessage: "" });
  };

  onDayChange = (event) => {
    this.setState({ vd: event.target.value, errorMessage: "" });
  };

  onYesClick = () => {
    // Validate the note
    if (!Utility.isNoteValid(this.state.note)) {
      this.setState({ errorMessage: Strings("invalidNote") });
      return;
    }
    
    //NOSONAR
    if (this.props.isApproveMinimart) {
      this.props.isNotesCompulsory && this.state.note
        ? this.props.addNote(this.state.note)
        : this.setState({ errorMessage: Strings("pleaseEnterNote") });
    } else if (this.props.isNotesCompulsory) {
      if (this.state.note !== "" && this.state.note.trim().length > 0) {
        if (this.props.noteType) {
          if (this.props.isEditNote) {
            this.props.editNote(this.state.note, this.props.noteType);
          } else if (
            (this.props.noteType === ANALYST_ACTION.APPROVE ||
              this.props.noteType ===
              ANALYST_ACTION.APPROVE_WITH_CONCILIATION) &&
            (this.props.poi || this.props.mod)
          ) {
            if (this.validateCedulaId()) {
              this.props.addNote(
                this.state.note,
                this.props.noteType,
                this.state.ownerCedulaId
              );
            }
          } else {
            this.props.addNote(this.state.note, this.props.noteType);
          }
        } else {
          this.props.addNote(this.state.note);
        }
      } else {
        this.setState({ errorMessage: Strings("pleaseEnterNote") });
      }
    } else if (!this.props.isNotesCompulsory) {
      if (
        this.props.noteType &&
        this.state.vd === "" &&
        this.state.note !== ""
      ) {
        this.props.isEditNote
          ? this.props.editNote(this.state.note, this.props.noteType)
          : this.props.addNote(this.state.note, this.props.noteType);
      } else if (
        !this.props.noteType &&
        this.state.vd === "" &&
        this.state.note !== ""
      ) {
        this.props.addNote(this.state.note);
      } else if (this.props.noteType && this.state.vd !== "") {
        this.props.addNote(this.state.note, this.state.vd);
      } else if (
        !this.state.note &&
        this.state.vd === "" &&
        this.props.noteType === APPROVED_MINIMART_ACTION.APPROVED
      ) {
        this.setState({ errorMessage: Strings("pleaseSelectDay") });
      } else {
        this.props.addNote(this.state.note);
      }
    } else if (
      this.props.isNotesCompulsory &&
      this.state.note === ""
    ) {
      this.setState({ errorMessage: Strings("pleaseEnterNote") });
    }
  };

  renderModalButtons = () => {
    return (
      <Row className='modalButton'>
        <Col md={12}>
          <Button
            className='buttonBackground primaryModalButton buttonMinWidth'
            onClick={this.onYesClick}
            disabled={
              this.props.disabled || this.state.errorMessage !== ""
            }
          >
            {Strings("Set")}
          </Button>

          {this.props.noteType === NOTES_TYPE.COL_OFF_EMI_INPUT &&
            this.props.isEditNote && (
              <Button
                className={
                  "buttonMinWidth marginCancelButton redButtonBorder"
                }
                onClick={this.props.deleteNote}
                disabled={this.props.disabled}
              >
                {Strings("DELETE")}
              </Button>
            )}

          <Button
            className={
              " primaryModalButton buttonMinWidth marginCancelButton buttonBorder"
            }
            onClick={this.closeModal}
          >
            {Strings("Cancel")}
          </Button>
        </Col>
      </Row>
    );
  };

  renderVisitedDays = () => {
    let days = [
      {
        id: "days",
        type: SEARCH_FILTER_TYPE.SELECTION,
        column: 11,
        handleOnChange: this.onDayChange,
        Value: this.state.vd,
        filterDropdownList: VISITED_DAYS,
      },
    ];
    return (
      <Row>
        <Col
          md={{ span: 3, offset: 2 }}
          className='justify-content-center align-self-center px-0'
        >
          <span>{Strings("visitDay")}</span>
        </Col>
        <Col md={6} className='pe-0'>
          <SearchFilter filters={days} hideSearchButtons={true} />
        </Col>
      </Row>
    );
  };

  renderAuthorInfo = () => {
    return (
      <Row className='pt-1 pb-0 notesModal'>
        <Col
          md={12}
          className='authorInfo d-flex justify-content-end'
        >{`${Strings("LastModified")} : ${this.props.author} | ${this.props.createdAt
          }`}</Col>
      </Row>
    );
  };

  validateCedulaId = () => {
    let isValid = true;
    if (this.state.ownerCedulaId === "") {
      isValid = false;
      this.setState({
        errorMessage: Strings("pleaseEnterRequiredFields"),
      });
    } else if (
      isNaN(this.state.ownerCedulaId) ||
      this.state.ownerCedulaId.length !== 11
    ) {
      this.setState({
        errorMessage: Strings("CedulaIdMustBeElevenDigits"),
      });
      isValid = false;
    }
    return isValid;
  };

  renderCedulaId = () => {
    return (
      <Row className={this.state.errorMessage ? "pb-2" : "py-2"}>
        <Col md={12}>
          {Strings("ownerCedulaNumber")}{" "}
          {<span className='mandatoryFields'>*</span>}
        </Col>
        <Col md={12}>
          <NumericFormat
            name={"ownerCedulaId"}
            className='form-control w-100'
            onValueChange={(event) => {
              this.setState({
                ownerCedulaId: event.value,
                errorMessage: "",
              });
            }}
            allowLeadingZeros
            value={this.state.ownerCedulaId}
            type='text'
            placeholder={Strings("ownerCedulaNumber")}
            allowNegative={false}
          />
        </Col>
      </Row>
    );
  };

  render() {
    return (
      <div className='notesModal'>
        <Modal
          // Change visibility of modal based on props.
          open={this.state.open}
          onClose={this.closeModal}
          closeOnEsc={false}
          closeOnOverlayClick={false}
          classNames={{ modal: "customModal" }}
          showCloseIcon={false}
          center
        >
          <Row className='notesModal'>
            <Col md={12} className='headerModal'>
              {this.props.modalHeader}
            </Col>
            <Col md={12} className='modalContent'>
              {this.state.errorMessage && (
                <Row
                  style={{ textAlign: "center" }}
                  className='errors'
                >
                  {this.state.errorMessage}
                </Row>
              )}
              {(this.props.noteType === ANALYST_ACTION.APPROVE ||
                this.props.noteType ===
                ANALYST_ACTION.APPROVE_WITH_CONCILIATION) &&
                (this.props.poi || this.props.mod) &&
                this.renderCedulaId()}
              {((this.props.noteType !==
                NOTES_TYPE.COL_OFF_EMI_INPUT &&
                !this.props.isOperationsOfficer &&
                !this.props.isOperationsModerator) ||
                (this.props.noteType ===
                  NOTES_TYPE.COL_OFF_EMI_INPUT &&
                  !this.props.disabled)) && (
                  <Row className='userInput '>
                    <Col md={12}>
                      <div className='inputFields'>
                        <textarea
                          className={
                            this.props.disabled
                              ? "disableText w-100"
                              : "w-100"
                          }
                          id='Notes'
                          type='text'
                          placeholder={Strings("Notes")}
                          maxLength={this.props.maxLength}
                          value={this.state.note}
                          cols={45}
                          rows={5}
                          onChange={this.handleChangeValue}
                          disable={this.props.disabled || undefined}
                        />
                      </div>
                    </Col>
                  </Row>
                )}
              {this.props.disabled &&
                this.props.noteType ===
                NOTES_TYPE.COL_OFF_EMI_INPUT && (
                  <Row className='userInput jumbotron mb-1 p-3'>
                    <Col md={12}>
                      <div className='inputFields text-wrap text-break'>
                        <div className='w-100 text-start'>
                          {this.state.note}
                        </div>
                      </div>
                    </Col>
                  </Row>
                )}
              {this.props.isOperationsOfficer &&
                this.props.isOperationsModerator &&
                this.props.isApproveMinimart &&
                this.props.isApproveClicked &&
                this.renderVisitedDays()}
              {this.props.noteType === NOTES_TYPE.COL_OFF_EMI_INPUT &&
                this.props.isEditNote &&
                this.renderAuthorInfo()}

              <hr className='Noteshr opacity-100' />
              {this.renderModalButtons()}
            </Col>
          </Row>
        </Modal>
      </div>
    );
  }
}

NotesModal.propTypes = {
  modalHeader: PropTypes.string,
  toggleModal: PropTypes.func,
  errorMessage: PropTypes.string,
};

NotesModal.defaultProps = {
  errorMessage: "",
};

export default NotesModal;
