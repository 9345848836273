import React, { Component } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import moment from "moment";

//Components
import Pagination from "../CustomUIComponents/Pagination/Pagination";
import CheckboxComponent from "../CustomUIComponents/CheckboxComponent/CheckboxComponent";

//Constants
import { PAID_INVOICE_TABLE } from "./Constants.js";
import {
  CI_BANK_TRANSFER_INVOICE_TYPE,
  INVOICE_DATE_FORMAT,
} from "../../constants/appConstants";

//Strings
import { Strings } from "../../resources/i18n/i18n";

//Utilities
import Utility from "../../utils/Utility";
class InvoiceList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOptions: [],
    };
  }

  renderTableHeader = (column) => {
    if (this.props.type === "paidInvoice" && column.key === "st") {
      return null;
    } else if (
      this.props.type === "unpaidInvoice" &&
      column.key === "st"
    ) {
      return (
        <>
          <span>{Strings(column.name)}</span>
          <CheckboxComponent
            onChange={() =>
              this.onSelectCheckbox(this.props.users, true)
            }
            checked={
              this.state.selectedOptions.length ===
              this.props.users.length
            }
            className='justify-content-center'
          />
        </>
      );
    } else {
      return column.name;
    }
  };

  renderTableData = (row, key) => {
    switch (key) {
      case "st":
        return (
          row[key] === CI_BANK_TRANSFER_INVOICE_TYPE.UNPAID && (
            <CheckboxComponent
              onChange={() => this.onSelectCheckbox([row])}
              checked={
                row[key] === CI_BANK_TRANSFER_INVOICE_TYPE.UNPAID
                  ? this.state.selectedOptions.includes(row.inv)
                  : true
              }
            />
          )
        );

      case "amt":
        return Utility.getCurrencyRepresentationOfAmount(row[key]);
      case CI_BANK_TRANSFER_INVOICE_TYPE.PAID:
        return null;
      case "dd":
        return moment(row[key]).format(INVOICE_DATE_FORMAT);
      default:
        return row[key] ? Strings(row[key]) : Strings("NA");
    }
  };

  renderTableClass = (key, row) => {
    if (key === "mn" || key === "cd" || key === "vd") {
      return "text-start px-2";
    } else if (row && key === "st" && row["st"] === "UNPAID") {
      return "d-flex justify-content-center px-2";
    } else {
      return "text-center px-2";
    }
  };

  onSelectCheckbox = (rows, selectAll) => {
    let selectedOptions =
      selectAll &&
        this.state.selectedOptions.length > 0 &&
        this.state.selectedOptions.length !== this.props.users.length
        ? []
        : this.state.selectedOptions;
    let indexOfOption = [];
    rows.forEach((checkboxValue) => {
      indexOfOption = selectedOptions.indexOf(checkboxValue.inv);
      if (indexOfOption === -1) {
        selectedOptions.push(checkboxValue.inv);
      } else {
        selectedOptions.splice(indexOfOption, 1);
      }
    });
    this.setState({ selectedOptions, errorMessage: "" });
  };

  renderTable = (columns, users) => {
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
      [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.divider,
        color: theme.palette.common.black,
      },
      [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
      },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
      "&:nth-of-type(even)": {
        backgroundColor: theme.palette.action.hover,
      },
      // hide last border
      "&:last-child td, &:last-child th": {
        border: 0,
      },
    }));

    return (
      <div className='mx-3 mb-3'>
        <TableContainer component={Paper}>
          <Table aria-label='customized table'>
            <TableHead>
              <TableRow>
                {columns.length > 0 &&
                  columns.map((column) => (
                    <StyledTableCell
                      key={column.key}
                      className={`${this.renderTableClass(
                        column.key
                      )} header`}
                      title={column.name}
                    >
                      <div>{this.renderTableHeader(column)}</div>
                    </StyledTableCell>
                  ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((row, rowIndex) => (
                <StyledTableRow
                  key={rowIndex + row.inv}
                  hover
                  style={{ cursor: "pointer" }}
                >
                  {columns.map((column) => (
                    <StyledTableCell
                      key={column.key}
                      className={`${this.renderTableClass(
                        column.key,
                        row
                      )}`}
                      component='th'
                      scope='row'
                      title={Strings(row[column.key])}
                      style={{ minWidth: column.minWidth }}
                    >
                      {this.renderTableData(row, column.key)}
                    </StyledTableCell>
                  ))}
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  };

  submitInputs = () => {
    if (this.state.selectedOptions.length > 0) {
      this.props.submitInputs(this.state.selectedOptions);
    } else {
      this.setState({
        errorMessage: Strings("pleaseSelectCheckboxesBeforeSubmit"),
      });
    }
  };

  clearSelection = () => {
    this.setState({
      selectedOptions: [],
    });
  };

  renderPagination = () => {
    if (this.props.activeTab === 0) {
      return (
        <Pagination
          activePage={this.props.AssignRepaymentPagination.activePage}
          recordsPerPage={this.props.AssignRepaymentPagination.recordsPerPage}
          numberOfPages={this.props.numberOfPages}
          onPageChange={this.props.onPageChange}
          dropup={true}
        />
      );
    } else {
      return (
        <Pagination
          activePage={this.props.activePage}
          recordsPerPage={this.props.recordsPerPage}
          numberOfPages={this.props.paidNumberofPages}
          onPageChange={this.props.onPageChange}
          dropup={true}
        />
      );
    }
  };
  render() {
    const { users } = this.props;
    return (
      <div>
        <Row>
          <Col md={12} className=' errorText'>
            {this.props.errorMessage}
          </Col>
        </Row>
        {users && users.length > 0 ? (
          <React.Fragment>
            {" "}
            {this.renderTable(PAID_INVOICE_TABLE, users)}
            {this.props.type !== "paidInvoice" && (
              <Row className='alignButtonToRight'>
                <Button
                  className='buttonBorder me-2 w-auto'
                  onClick={this.clearSelection}
                >
                  {Strings("Clear")}
                </Button>
                <Button
                  className='buttonBackground w-auto'
                  onClick={this.submitInputs}
                >
                  {Strings("Submit")}
                </Button>
              </Row>
            )}
            {this.renderPagination()}
          </React.Fragment>
        ) : (
          <div className='noRecordsFound'>
            {Strings("NoRecordsFound")}
          </div>
        )}
      </div>
    );
  }
}

export default InvoiceList;
